.background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 440px;
}

@media (max-width: 1024px) {
    .background {
        background-image: none !important;
        border-radius: 30px;
        height: auto;
    }
}